header  {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 20;
}

.App {
  text-align: center;
}

