@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');

:root {
    --vh: 1vh;
}

html, body {
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-family: 'Pattaya', sans-serif;
}

body {
    position: relative;
    max-height: 100%;
}

.mainmap {
    padding: 0;
    margin: 0;
    height: 50vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    overflow: hidden;
}
