@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        margin-left: 1rem;
    }

    .MainHeader {
        /*    animation: App-logo-spin infinite 20s linear;*/
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.Jatskikiskat-logo {
    height: 3em;
    pointer-events: none;
}

.MainHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: #333;
}

.background-round {
    background-color: #fff;
    height: 5em !important;
    width: 5em !important;;
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    cursor: pointer
}

.background-round .logo-title {
    font-size: 0.6em;
    margin-top: 0em;
}

.background-round .kiosk_logo_ice_cream {
    font-size: 0.8em;
    padding-top: 0.5em;
}


.MainHeader .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.MainHeader .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.MainHeader__left {
    position: absolute;
    left: 1em;
    height: 2em;
}

.MainHeader__right {
    position: absolute;
    right: 2em;
    height: 2em;
}


.info_box_container {
    position: absolute;
    padding: 0;
    top: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
}

.info_box {
    position: relative;

    width: 90%;
    max-width: 600px;

    top: 2%;
    max-height: 90%;

    margin: auto;
    padding: 10px;

    background: #fff;
    color: #333;

    border-radius: 5px;
    border: 1px solid #333;

    cursor: pointer;

    z-index: 20;

    animation: slow-opacity-in 0.3s ease;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;

    font-weight: 100;
}

.info_box .header,
.info_box .chapter {
    font-weight: 100;
}
