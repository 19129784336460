@import url(https://fonts.googleapis.com/css2?family=Pattaya&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header  {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 20;
}

.App {
  text-align: center;
}


:root {
    --vh: 1vh;
}

html, body {
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-family: 'Pattaya', sans-serif;
}

body {
    position: relative;
    max-height: 100%;
}

.mainmap {
    padding: 0;
    margin: 0;
    height: 50vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    overflow: hidden;
}

@-webkit-keyframes slow-opacity-out {
    from {opacity: 0.7;}
    to {opacity: 0.2;}
}

@keyframes slow-opacity-out {
    from {opacity: 0.7;}
    to {opacity: 0.2;}
}

.kiosk_logo_ice_cream_container {
    color: #333;
    position: relative;
    left: -2.4em;
    top: -4.5em;
    cursor: pointer;
}

.kiosk_logo_ice_cream img {
    width: 3em;
}

.kiosk_logo_ice_cream_container .kiosk_logo_ice_cream img {
    width: 5em;
}

.kiosk_description {
    margin-bottom: 1em;
}
/*
.kiosk_logo_ice_cream {
    font-size: 2em;
    color: #666;
    position: absolute;
    left: -0.4em;
    top: -1em;
}
.kiosk_logo_ice_cream_shadow {
    font-size: 2.1em;
    color: #ddd;
    position: absolute;
    left: -0.3em;
    top: -0.9em;
}
*/
.hover_box, .hover_box_popop_open {
    padding: 10px;
    min-width: 180px;
    color: #333;
    border-radius: 5px;
    border: 1px solid #333;
    cursor: pointer;
    z-index: 10;
}
.hover_box {
    background-color: rgba(255,255,255,0.7);
}
.hover_box_popop_open {
    opacity: 0.2;
    -webkit-animation: slow-opacity-out 2s ease;
            animation: slow-opacity-out 2s ease;
}

.logo_container {
    font-size: 2em;
}

@-webkit-keyframes slow-opacity-in {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

@keyframes slow-opacity-in {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.closewindow {
    text-align: right;
    padding-right: 20px;
    padding-top: 10px;
    color: #333;
}


.popup_box_container {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
}

.popup_box {
    position: relative;

    width: 90%;
    max-width: 600px;

    top: 2%;
    max-height: 90%;

    margin: auto;
    padding: 0px;

    background: #fff;
    color: #333;

    border-radius: 5px;
    border: 1px solid #333;

    cursor: pointer;

    z-index: 20;

    -webkit-animation: slow-opacity-in 0.3s ease;

            animation: slow-opacity-in 0.3s ease;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.popup_box .header {
    background-color: #EBBBE1;
    background-image: linear-gradient(to bottom right, #EBBBE1, #A97DA0);
    color: #333;
    border-bottom: 1px solid #aaa;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.popup_box .header .header_container {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.popup_box .header .logo_container .kiosk_logo,
.popup_box .header .logo_container .jatskikiskat {
    width: 1.5em;
}


.popup_box .header .content .kioskname {
    font-size: 1.2em;
    padding-left: 1em;
    padding-right: 1em;
}

.popup_box .tailer {
    padding-top: 1em;
    margin-bottom: 1em;
}

.kiosk_image {
    width: 90%;
}

.kiosk_stars {
    width: 90%;
}

.kioskname {
    font-family: 'Pattaya', sans-serif;
    font-weight: 100;
}

.on_sale_list {
    display: inline;
    list-style: none;
    text-align: center;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.on_sale_list li {
    display: inline;
}

.on_sale_list li:after {
    content: ", ";
}

.on_sale_list li:last-child:after {
    content: "";
}

.payment_options_list {
    display: inline;
    list-style: none;
    text-align: center;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.payment_options_list li {
    display: inline;
}

.payment_options_list li:after {
    content: ", ";
}

.payment_options_list li:last-child:after {
    content: "";
}

.kiosk_logo {
    width: 40px;
}

.logo_container .jatskikiskat {
    width: 2em;
}

.social_media_container {
    margin-top: 2em;
    margin-bottom: 2em;
}
.social_media_list {
    display: inline;
    list-style: none;
    text-align: center;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.social_media_list li {
    display: inline;
    padding-left: 0.3em;
    padding-right: 0.3em;
    font-size: 2em;
}


.social_media_list li.facebook {
    color: #4267B2;
}

.social_media_list li.instagram {
    color: #e95950 ;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        margin-left: 1rem;
    }

    .MainHeader {
        /*    animation: App-logo-spin infinite 20s linear;*/
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.Jatskikiskat-logo {
    height: 3em;
    pointer-events: none;
}

.MainHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 1vmin);
    color: #333;
}

.background-round {
    background-color: #fff;
    height: 5em !important;
    width: 5em !important;;
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    cursor: pointer
}

.background-round .logo-title {
    font-size: 0.6em;
    margin-top: 0em;
}

.background-round .kiosk_logo_ice_cream {
    font-size: 0.8em;
    padding-top: 0.5em;
}


.MainHeader .row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.MainHeader .column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.MainHeader__left {
    position: absolute;
    left: 1em;
    height: 2em;
}

.MainHeader__right {
    position: absolute;
    right: 2em;
    height: 2em;
}


.info_box_container {
    position: absolute;
    padding: 0;
    top: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
}

.info_box {
    position: relative;

    width: 90%;
    max-width: 600px;

    top: 2%;
    max-height: 90%;

    margin: auto;
    padding: 10px;

    background: #fff;
    color: #333;

    border-radius: 5px;
    border: 1px solid #333;

    cursor: pointer;

    z-index: 20;

    -webkit-animation: slow-opacity-in 0.3s ease;

            animation: slow-opacity-in 0.3s ease;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;

    font-weight: 100;
}

.info_box .header,
.info_box .chapter {
    font-weight: 100;
}

