@keyframes slow-opacity-out {
    from {opacity: 0.7;}
    to {opacity: 0.2;}
}

.kiosk_logo_ice_cream_container {
    color: #333;
    position: relative;
    left: -2.4em;
    top: -4.5em;
    cursor: pointer;
}

.kiosk_logo_ice_cream img {
    width: 3em;
}

.kiosk_logo_ice_cream_container .kiosk_logo_ice_cream img {
    width: 5em;
}

.kiosk_description {
    margin-bottom: 1em;
}
/*
.kiosk_logo_ice_cream {
    font-size: 2em;
    color: #666;
    position: absolute;
    left: -0.4em;
    top: -1em;
}
.kiosk_logo_ice_cream_shadow {
    font-size: 2.1em;
    color: #ddd;
    position: absolute;
    left: -0.3em;
    top: -0.9em;
}
*/
.hover_box, .hover_box_popop_open {
    padding: 10px;
    min-width: 180px;
    color: #333;
    border-radius: 5px;
    border: 1px solid #333;
    cursor: pointer;
    z-index: 10;
}
.hover_box {
    background-color: rgba(255,255,255,0.7);
}
.hover_box_popop_open {
    opacity: 0.2;
    animation: slow-opacity-out 2s ease;
}

.logo_container {
    font-size: 2em;
}
