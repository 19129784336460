@keyframes slow-opacity-in {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.closewindow {
    text-align: right;
    padding-right: 20px;
    padding-top: 10px;
    color: #333;
}


.popup_box_container {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
}

.popup_box {
    position: relative;

    width: 90%;
    max-width: 600px;

    top: 2%;
    max-height: 90%;

    margin: auto;
    padding: 0px;

    background: #fff;
    color: #333;

    border-radius: 5px;
    border: 1px solid #333;

    cursor: pointer;

    z-index: 20;

    animation: slow-opacity-in 0.3s ease;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.popup_box .header {
    background-color: #EBBBE1;
    background-image: linear-gradient(to bottom right, #EBBBE1, #A97DA0);
    color: #333;
    border-bottom: 1px solid #aaa;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.popup_box .header .header_container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.popup_box .header .logo_container .kiosk_logo,
.popup_box .header .logo_container .jatskikiskat {
    width: 1.5em;
}


.popup_box .header .content .kioskname {
    font-size: 1.2em;
    padding-left: 1em;
    padding-right: 1em;
}

.popup_box .tailer {
    padding-top: 1em;
    margin-bottom: 1em;
}
