.payment_options_list {
    display: inline;
    list-style: none;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}

.payment_options_list li {
    display: inline;
}

.payment_options_list li:after {
    content: ", ";
}

.payment_options_list li:last-child:after {
    content: "";
}
