.social_media_container {
    margin-top: 2em;
    margin-bottom: 2em;
}
.social_media_list {
    display: inline;
    list-style: none;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}

.social_media_list li {
    display: inline;
    padding-left: 0.3em;
    padding-right: 0.3em;
    font-size: 2em;
}


.social_media_list li.facebook {
    color: #4267B2;
}

.social_media_list li.instagram {
    color: #e95950 ;
}

